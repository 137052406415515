// © 2021 .Kritin Digital solutions  All rights reserved.
// Original Author: Lokesh Rajput
// Original Date: 8 June 2021
@import "~ngx-toastr/toastr";
@import "~bootstrap/dist/css/bootstrap.css";
@import "~@mat-datetimepicker/core/datetimepicker/datetimepicker-theme.scss";
@import "~@angular/material/theming";
@import "../src/assets/style/responsive.scss";
@import "../src/assets/theme/default.scss";
@import "../src/assets/style/fonts.scss";
@import "../src/assets/style/custom.scss";

html,
body {
  height: 100%;
  font-size: 10px;
}

body {
  margin: 0;
   font-family: "Helvetica" !important;
  background: #f6f6f6;
}
* {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
button,
div,
th,
td,
tr,
ul,
li,
label,
table,
.btn {
  font-family: "Helvetica" !important;
  letter-spacing: 0.5px;
}

//header setup
.mat-toolbar-row {
  height: 5.5rem !important;
}
.log .mat-button-wrapper {
  display: flex;
  align-items: center;
}
.notificationBtn {
  .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
    right: -3px;
    top: -3px;
    width: 16px !important;
    height: 16px !important;
    line-height: 16px !important;
    font-size: 1.2rem;
  }
}
 

// For Table setup
.bank{
  .mainTable {
    height: auto !important;
    min-height: auto !important;
    overflow: auto;
  }
}
// table tab fix
.orderTable{
  .mainTable {
    height: auto !important;
    min-height: auto !important;
    overflow: auto;
  }
}
.tab_table { 
  // .mainTable {
  //   height: calc(100vh - 200px) !important;
  //   min-height: calc(100vh - 200px) !important;
  //   overflow: auto;
  // }
  .mainTable {
    height: calc(100vh - 275px) !important;
    min-height: calc(100vh - 275px) !important;
    overflow: auto;
  }
  .mat-tab-body {
    padding: 0px;
    margin: 0px;
  }
}
th.mat-header-cell {
  font-size: 1.3rem; 
}

.Data_table thead tr:hover {
  background-color: #fff;
}

.mat-list-item-content {
  width: 100%;
}

.mat-sort-header-content {
  white-space: nowrap;
}

table.mat-table {
  width: 100%;
}
// Main contant body
.main-body {
  padding: 10px;
}

.mat-drawer-container {
  background-color: #f6f6f6 !important;
}

// input setup
.mat-form-field-infix {
  border-top: inherit !important;
  // margin-top: 5px;
}

mat-form-field {
  mat-hint {
    line-height: 1.1;
    -webkit-line-clamp: 1 !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
  }
}
.table_main_div {
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.9rem 0rem !important;
  }
} 
.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0px !important;
  .mat-form-field-label {
    color: #939393 !important;
    font-size: 1.3rem !important;
    line-height: 20px !important;
    margin-top: -0.4em;
  }
  .mat-select.mat-select-invalid .mat-select-arrow {
    color: #939393 !important;
  }
}

// .mat-form-field-appearance-outline .mat-form-field-flex {
//     height: 4rem !important;
// }

.textArea.mat-form-field-appearance-outline .mat-form-field-flex {
  height: inherit !important;
}

.set-textAria textarea.mat-input-element {
  height: 80px;
  line-height: 1.4;
}

.mat-tab-body {
  margin: 8px 15px;
}

.mat-tab-body-content {
  overflow: inherit !important;
} 
.table_main_div {
  .mat-form-field-appearance-outline .mat-form-field-flex {
    margin-top: 0rem !important;
  }
  .mat-paginator-page-size-select {
    margin: 0px 4px 0 4px !important;
  }
  .mat-paginator-container {
    min-height: 50px !important;
}
}
.card_set .mat-card-content {
  min-height: calc(100vh - 257px);
}
.mat-card-actions {
  margin: 0 !important;
  padding: 10px !important; 
  text-align: right;
}
.mat-form-field {
  width: 100%;
  margin-bottom: 20px;
  line-height: 1 !important;
}

.p-0 {
  padding: 0 !important;
}

.mat-form-field-wrapper {
  padding-bottom: 0px !important;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: var(--primary-color) !important;
  height: 3px;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: var(--primary-color) !important;
}
.mat-form-field.mat-focused .mat-form-field-label {
  color: var(--primary-color) !important;
}

.mat-select-arrow-wrapper {
  vertical-align: bottom !important;
  transform: translateY(-35%);
}
.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: var(--primary-color) !important;
}

.required kendo-label:after {
  content: "*" !important;
  color: red !important;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  font-size: 1.6rem !important;
  //@Narendra
  transform: translateY(-1.29em) scale(0.75) !important;
}

// checkbox styling Start
// Background border
.mat-checkbox .mat-checkbox-frame:hover,
.mat-checkbox .mat-checkbox-frame:focus {
  background-color: inherit;
}

.mat-checkbox .mat-checkbox-frame {
  border: 0.15rem solid #898989 !important;
  background-color: transparent !important;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled) .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled) .mat-ripple-element,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--primary-color) !important;
}

// brand Grade checkobox
.applicableGrid,
.brandGrade {
  .mat-checkbox {
    margin-bottom: 10px;
    .mat-checkbox-label {
      font-weight: 600;
    }
  }
}
.checkbBoxGrid {
  .mat-checkbox {
    margin-bottom: 5px;
    label {
      width: 100%;
      .mat-checkbox-label {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: block;
        width: 100%;
      }
    }
  }
}

.associateHeader {
  align-items: center;
  margin-bottom: 15px;
  .mat-checkbox {
    .mat-checkbox-label {
      font-weight: 600;
    }
  }
}

// checkbox styling End
// radio styling Start

.mat-radio-group {
  margin: 0.25em 0;
  display: inline-block;
  padding-bottom: 0.8rem !important;
}

// vertical Radio Btn

.verticalRadio .mat-radio-group {
  display: flex;
  flex-direction: column;
  .mat-radio-button {
    // margin-bottom: 10px;
    padding-left: 0px;
  }
  .mat-radio-button ~ .mat-radio-button {
    margin-left: 0px !important;
  }
}

.mat-radio-container {
  height: 1.6rem !important;
  width: 1.6rem !important;
}

.mat-radio-outer-circle {
  height: 1rem;
  width: 1rem;
}

.mat-radio-inner-circle {
  height: 1rem;
  width: 1rem;
}

.mat-radio-button .mat-radio-ripple {
  height: 1.5rem;
  /*double of your required circle radius*/
  width: 1.5rem;
  /*double of your required circle radius*/
  left: calc(50% - 1rem);
  /*'10px'-same as your required circle radius*/
  top: calc(50% - 1rem);
  /*'10px'-same as your required circle radius*/
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: var(--primary-color) !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--primary-color) !important;
}

.mat-radio-outer-circle {
  height: 1.6rem !important;
  width: 1.6rem !important;
  border-width: 0.1rem !important;
}

.mat-radio-inner-circle {
  height: 1.6rem !important;
  width: 1.6rem !important;
}
 
// radio styling End
.mainCard {
  height: calc(100vh - 120px);
  overflow: auto;
  .mat-tab-body.mat-tab-body-active {
    position: relative;
    overflow-x: hidden;
    overflow-y: inherit;
    z-index: 1;
    flex-grow: 1;
  }
  .mat-tab-labels {
    border-bottom: 1px solid #c7c7c7;
  }
  .mat-tab-label {
    height: 50px; 
    justify-content: center;
  }
  .mat-tab-label-active {
    color: #25282b !important;
  }
}

// sidenav
.close {
  width: 80px;
  .submenu {
    display: none;
  }
  .mat-list-item {
    .itemName,
    .arrow {
      display: none;
    }
  }
  .userDetails {
    display: none;
  }
  &:hover {
    .mat-list-item {
      .itemName,
      .arrow,
      .submenu {
        display: block;
      }
    }
    .submenu {
      display: block;
    }
    .userDetails {
      display: block;
    }
  }
}
.close:not(:hover) .mat-list-item .routeIcon {
  margin-right: 0px !important;
}

.close .userArea .user {
  width: 4.5rem !important;
  height: 4.5rem !important;
}
.mat-drawer .mat-drawer-inner-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

// calender css
.mat-datetimepicker-calendar-header {
  background-color: #74aff0;
}

// for date picker
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: var(--primary-color) !important;
}
.mat-datepicker-toggle-active,
.mat-datepicker-toggle-focused {
  color: var(--primary-color) !important;
}
.mat-calendar-body-selected {
  background-color: var(--primary-color) !important;
}

.mat-form-field:not(.mat-form-field-appearance-legacy)
  .mat-form-field-prefix
  .mat-icon-button,
.mat-form-field:not(.mat-form-field-appearance-legacy)
  .mat-form-field-suffix
  .mat-icon-button {
  width: 1.5em !important;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgb(255 222 174) !important;
}

// .mat-list-base .mat-list-item {
//     //@at-root: #333 !important;
// }

// for multi input component
.multiInput {
  .mat-form-field-infix {
    display: flex;
  }
  .mat-select {
    width: 40%;
    border-left: 1px solid #c1c1c1;
    padding-left: 10px;
  }
  .mat-form-field-appearance-outline .mat-form-field-flex {
    padding-right: 0.5rem;
  }
}
// Select css

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--primary-color) !important;
}

// for custom scrollbar style
/* width */

::-webkit-scrollbar {
  width: 0.7rem;
  height: 0.6rem;
}
/* Track */

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0);
}
/* Handle */

::-webkit-scrollbar-thumb {
  background: #afafaf;
  border-radius: 10px;
}
/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #949494;
}

// for tab bar fix
.mainCard {
  .mat-tab-header {
    background-color: #fff;
    position: sticky !important;
    top: -10px;
    left: 0;
    z-index: 2;
    border-bottom: 0px;
  }
  .mat-tab-body-wrapper {
    min-height: calc(100vh - 27rem);
  }
  .mat-tab-label {
    font-size: var(--title-font-size);
    color: #757575;
    opacity: 1;
  }
}

// for mat card
.mat-elevation-z8 {
  box-shadow: 0 9px 14px #7777771f !important;
  -webkit-box-shadow: 0 9px 14px #7777771f !important;
  -moz-box-shadow: 0 9px 14px #7777771f !important;
  box-shadow: 0 9px 14px #7777771f !important;
}

.mainTable {
  .mat-paginator-outer-container {
    height: 55px;
  }
  .mat-paginator-container {
    min-height: 55px;
    padding: 0px;
    .mat-form-field-wrapper {
      padding-bottom: 0px !important;
    }
  }
  .mat-form-field-appearance-outline .mat-form-field-flex {
    margin-top: 0px !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 1.2rem 0 1.2rem 0 !important;
    margin-top: 3px !important;
  }
  .mat-paginator,
  .mat-paginator-page-size .mat-select-trigger {
    font-size: 13px;
  }
  .mat-paginator-page-size-select {
    margin: 0px 4px 0 4px;
  }
  .mat-paginator-range-label {
    margin: 0 32px 0 15px;
  }
  th.mat-header-cell {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .mat-sort-header-content {
    display: inline-block !important;
    text-align: left !important;
  }
 
  th.mat-header-cell:last-of-type,
  td.mat-cell:last-of-type,
  td.mat-footer-cell:last-of-type {
    padding-right: 1.5rem;
    white-space: nowrap;
    
  }
  th.mat-header-cell:first-of-type,
  td.mat-cell:first-of-type,
  td.mat-footer-cell:first-of-type { 
    padding-left: 10px;
    min-width:20px;
  }
  th.mat-header-cell,
  td.mat-cell,
  td.mat-footer-cell {
    padding-left: 1.5rem;
    min-width:100px;
  }
  // .mat-sort-header-container{
  //     justify-content: center;
  // }
  .mat-cell,
  .mat-footer-cell {
    color: var(--secondary-color-text);
    font-size: 13px;
    letter-spacing: 0.2px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .file {
    .mat-sort-header-container {
      justify-content: center;
      // border-top: 10px solid var(--primary-color);
    }
  }
  .imageSignedUrl,
  .getSignedUrlForImage,
  .getSingedUrl,
  .status,
  .action {
    .mat-sort-header-container {
      justify-content: center;
      // border-top: 10px solid var(--primary-color);
    }
    text-align: center;
    margin: 0 auto;
  }
  tr:hover {
    background-color: #f6f6f6;
  }
}

// filter search area
.openSearch {
  th.mat-header-cell {
    vertical-align: top;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  tr.mat-header-row {
    height: 5.5rem;
  }
}

/*  .mat-list-base .mat-list-item .mat-list-item-content,
    .mat-list-base .mat-list-option .mat-list-item-content {
    height: 48px !important;
} */

.tableHead {
  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    background-color: var(--secondary-color);
    color: #777;
    line-height: 35px !important;
    height: 35px;
    width: 35px;
    border-radius: var(--btn-radius);
    text-align: justify;
    transition: 0.3s;
    &:hover {
      background-color: var(--primary-color);
      color: var(--primary-color-text);
    }
    .material-icons-outlined {
      font-size: 2rem;
    }
  }
  .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
  .mat-button-toggle-group-appearance-standard {
    border: 0px;
    border-radius: 0px;
    background-color: transparent !important;
  }
  .mat-button-toggle-group-appearance-standard
    .mat-button-toggle:not(:last-child) {
      margin-left: 7px;
  }
  .mat-button-toggle-group-appearance-standard
    .mat-button-toggle
    + .mat-button-toggle {
    border-left: 0px;
  }
}

.formArea {
  padding: 1.5rem;
}

.mainCard {
  .mat-card-header { 
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    .mat-card-title {
      font-size: 1.5rem;
      margin-bottom: 0 !important;
      padding: 1rem 0.5rem;
    }
  }
}
.title {
  font-size: 16px;
  color: #25282b;
  letter-spacing: 0.5px;
}

// for card footer
button:hover {
  opacity: 0.9;
}

.mat-card-actions {
  background-color: #fff;
  z-index: 9;
  app-button {
    display: inline-block;
  }
  app-button:not(:last-child) {
    .btn {
      margin-right: 10px;
    }
  }
  button:not(:last-child) {
    margin-right: 10px;
  }
}
 
.mat-card-actions,
.mat-card-subtitle,
.mat-card-content {
  margin-bottom: 0 !important;
}

.mat-button-toggle-label-content {
  padding: 0 0.8rem !important;
}

.mat-header-row {
  height: 45px !important;
}

// Btn design
.btn-secondary {
  background-color: #fda100 !important;
  color: #fff !important;
  width: 100%;
  padding: 10px !important;
  border-color: #fda100 !important;
  margin-left: 0px !important;
}
.btn-secondary:active,
.btn-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgb(253 161 0 / 25%);
}
.form-footer app-button {
  width: 100%;
}

//Btn design end

//error msg
.error {
  color: red !important;
  font-size: 11px;
  letter-spacing: 0.5px;
}
.ml-auto {
  margin-left: auto !important;
}
// card css

.card,
.mat-card {
  box-shadow: inherit !important;
  border-radius: var(--btn-radius) !important;
}
.card:hover,
.mat-card:hover {
  box-shadow: 0px 3px 10px rgb(12 12 12 / 9%) !important; 
}
// .card-body {
//   padding: 15px;
// }

.mat-drawer-container {
  height: 100vh;
}
.toast-top-right {
  top: 100px !important;
}

//user menu

.user_menu .mat-menu-item .mat-icon {
  margin-right: 5px;
  width: 18px;
  height: 18px;
  font-size: 18px;
  line-height: 18px;
}

.file_upload {
  display: flex;
  padding-top: 8px;
  .box {
    border: 1px solid #e0e0e0;
    width: 100%;
    border-radius: var(--btn-radius);
    color: #9d9d9d;
    padding: 10px 10px;
    height: 42px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; 
  }
  ::-webkit-scrollbar {
    width: 0rem;
    height: 0rem;
  }
}
.hint_text {
  color: unset;
  font-size: 12px;
}
.btn_group app-button:not(:last-child) {
  margin-right: 15px;
}

// Staper Design CSS
.staper_design {
  .mat-horizontal-stepper-header-container {
    background-color: #fff;
    position: sticky !important;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .mat-horizontal-content-container {
    padding: 0px;
  }
  .mat-step-header .mat-step-icon {
    background-color: #ecebee;
    color: #757575;
  }
  .mat-step-header .mat-step-icon-selected,
  .mat-step-header .mat-step-icon-state-done,
  .mat-step-header .mat-step-icon-state-edit {
    background-color: var(--primary-color);
    color: var(--primary-color-text);
  }
  .mat-horizontal-stepper-header {
    height: 55px;
    width: 100%;
    //border-bottom: 1px solid #c7c7c7;
  }
  .mat-stepper-horizontal-line {
    border-top-width: 0px;
  }
  .mat-step-header .mat-step-label {
    color: #757575;
    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 1px;
      left: 0;
      color: #25282b;
      bottom: 0px;
      background-color: #c7c7c7;
      transition: 0.3s;
    }
  }
  .mat-step-header
    .mat-step-label.mat-step-label-active.mat-step-label-selected {
    color: #25282b;
  }
  .mat-step-header .mat-step-label-selected::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 3px !important;
    left: 0;
    bottom: 0px;
    background-color: var(--primary-color) !important;
    transition: 0.3s;
  }
  .mat-step-header:hover {
    background-color: rgb(0 0 0 / 1%);
  }
  .mat-step-label {
    font-size: var(--title-font-size);
  }
  .mat-step-icon {
    height: 30px;
    width: 30px;
    font-size: var(--title-font-size);
  }
  .mat-horizontal-stepper-content .mat-card-content {
    min-height: calc(100vh - 312px);
  }
}

.pagination_set {
  .mat-button.mat-primary.mat-button-disabled,
  .mat-button.mat-accent.mat-button-disabled,
  .mat-button.mat-warn.mat-button-disabled,
  .mat-button.mat-button-disabled.mat-button-disabled,
  .mat-icon-button.mat-primary.mat-button-disabled,
  .mat-icon-button.mat-accent.mat-button-disabled,
  .mat-icon-button.mat-warn.mat-button-disabled,
  .mat-icon-button.mat-button-disabled.mat-button-disabled,
  .mat-stroked-button.mat-primary.mat-button-disabled,
  .mat-stroked-button.mat-accent.mat-button-disabled,
  .mat-stroked-button.mat-warn.mat-button-disabled,
  .mat-stroked-button.mat-button-disabled.mat-button-disabled {
    background-color: var(--secondary-color);
    background-image: unset;
    border-radius: var(--btn-radius);
  }
  .mat-icon-button {
    margin-right: 5px;
    background-color: var(--primary-color);
    border-radius: var(--btn-radius);
    color: var(--primary-color-text);
    // width: 3rem;
    // height: 3rem;
    // line-height: 3rem;
  }
  &.custom {
    .mat-paginator-container {
      align-items: baseline;
    }
  }
}

//Login Page

.btn_set {
  .btn {
    min-width: 172px !important;
  }
}

.session_container {
  .mat-form-field-label {
    color: rgb(255 255 255 / 75%) !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: #fff;
  }
  .mat-form-field {
    margin-bottom: 20px;
    .mat-button-base {
      color: var(--primary-color) !important;
    }
  }
  .mat-select-arrow {
    color: #fff !important;
  }
  .mat-select-value-text,
  input.mat-input-element {
    color: #f0f0f0;
  }
  .mat-select-placeholder,
  ::placeholder {
    color: gray !important;
  }
  .btn {
    min-width: 172px !important;
  }
  .error {
    color: #ff4a4a !important;
  }
}

.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  top: calc(100% - 5px);
  line-height: unset;
  padding: 0px !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-start {
  border-radius: var(--fild-radius) 0px 0 var(--fild-radius) !important;
}
.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-radius: 0 var(--fild-radius) var(--fild-radius) 0 !important;
}

.mainTable th,
.mainTable td {
  max-width: 250px;
}
.mainTable {
  td.shortDescription {
    span {
      -webkit-line-clamp: 2 !important;
      overflow: hidden !important;
      display: -webkit-box !important;
      -webkit-box-orient: vertical !important;
    }
  }
}
// Loader Design

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: var(--primary-color) !important;
}
td,
.thumbnail {
  mat-spinner {
    width: 42px !important;
    height: 42px !important;
    margin: 0 auto;
    svg {
      width: 42px !important;
      height: 42px !important;
    }
  }
}
.hint_with_fild {
  margin-bottom: 20px;
  .mat-form-field {
    margin-bottom: 0px;
  }
}

// Filter
.filter {
  .mat-chip-list-wrapper {
    margin: 0px;
  }
}

// Modal Design

.mat-dialog-container {
  padding: 0px !important;
  .modalSection {
    position: relative;
    max-height: calc(100vh - 80px);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .colseBtn {
      cursor: pointer;
      position: absolute;
      top: 7px;
      right: 10px;
      color: #000;
      width: 28px;
      height: 28px;
      line-height: 28px;
      background-color: #ebebeb;
      mat-icon {
        font-size: 20px;
        // width: inherit;
        // height: inherit;
      }
      &:focus {
        background-color: transparent !important;
      }
    }
    .modalHeader {
      padding: 15px 30px 15px 30px;
      h4 {
        margin-bottom: 0px;
        font-size: 18px;
        color: #000;
      }
    }
    .modalBody {
      padding: 12px 30px 24px 30px;
      overflow: auto;
      .modalFooter {
        padding: 12px 0 0 0;
        text-align: right;
        app-button {
          display: inline-block;
          &:not(:last-child) {
            margin-right: 15px;
          }
        }
      }
    }
  }
}
.listHistoryTable {
  .historyTableMainDiv {
    padding: 0px !important;
    .table_main_div {
      border: 0px !important;
      .history_table {
        max-height: calc(100vh - 243px) !important;
      }
    }
  }
} 

.notificationSection {
  max-width: 400px !important;
  border-radius: 8px !important;
  box-shadow: 0px 3px 10px rgb(12 12 12 / 16%) !important;
  .mat-menu-content {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 90px);
  }
}

.angular-editor-toolbar {
  display: flex;
  align-items: center;
  padding: 5px 8px !important;
  border-radius: 5px 5px 0 0;
  flex-wrap: wrap;
  .angular-editor-toolbar-set {
    font-size: 15px;
    .angular-editor-button {
      height: 30px;
      color: #7a7a7a;
    }
  }
}

.productTable {
  table {
    th.publishedStatus,
    th.getSingedUrl {
      text-align: center;
      .mat-sort-header-container {
        justify-content: center;
      }
    }
    td.publishedStatus {
      text-align: center;
    }
  }
}

// Custom Mat Checkbox
.customMatCheckBox {
  label {
    -webkit-line-clamp: 1 !important;
    overflow: hidden !important;
    -webkit-box-orient: vertical !important;
    text-overflow: ellipsis;
    width: 100%;
    display: block !important;
  }
}

.mat-progress-bar-fill::after {
  background-color: var(--primary-color) !important;
}

.payment mat-tab-body {
  margin-top: 0 !important;
}

mat-pseudo-checkbox.mat-pseudo-checkbox.mat-option-pseudo-checkbox.ng-star-inserted.mat-pseudo-checkbox-checked {
  background: var(--primary-color) !important;
}
.mat-toolbar-row, .mat-toolbar-single-row{
    height: 55px !important;
}

.mat-paginator-icon {
  width: 22px !important; 
}

.mat-select-value-text { 
  font-size: 1.3rem; 
}
.mat-form-field-appearance-outline .mat-form-field-infix { 
  font-size: 1.3rem;
  margin-top: 0.4rem;
} 
.mat-drawer-content {
  position: relative;
  z-index: 1;
  display: block;
  height: 100%; 
}
.mat-paginator-container {
  height: 50px;
  min-height: 50px !important;
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.5rem 0 0.55em 0;
  }
}
.card-body { 
  padding: 2rem 1.5rem 1.5rem;
}
.customTable thead tr th {
  font-size: 1.3rem !important;
  font-weight: 600 !important;
}
 
.card { 
  border: 0.01px solid rgba(0, 0, 0, 0.125); 
}
.mat-icon-button {
  border-radius: 0.4rem !important;
}
.mat-paginator-container {
  .mat-icon-button {
    border-radius: 0.4rem !important; 
      width: 35px !important;
      height: 35px !important; 
      line-height: 35px !important;
  }
} 
.recentOrder {
  .mainTable {
    height: 196px !important;
    min-height: 196px !important; 
    overflow: auto;
}
}
.btn-primary,
.btn-primary:hover {
  background-color: var(--primary-color);
  color: var(--primary-color-text);
  padding: 10px 15px;
  font-size: var(--btn-font-size);
  border: 0px;
  border-radius: var(--btn-radius);
}
.mat-select-panel{
  margin-top:40px;
}
.close { 
  .mat-list-item-content {
    justify-content: center !important;
  }
}
.close:hover { 
  .mat-list-item-content {
    justify-content: inherit !important;
  }
}
.Receivable{ 
  .mat-form-field {
      margin-bottom: 0px;
  }
}
.recentOrder {
  // .table_main_div {
  //   background:linear-gradient(135deg, #fbfbfc 0%,#f6f7f9 100%) !important;
  // }
  .tableHead {
    background-color: inherit !important;
  }
}
.todocard {
  .mat-form-field { 
    margin-bottom: 10px; 
}
.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.5em 0 1.1em 0;
}

}

.dashboard {
  .mat-paginator { 
    border-radius: 0px 0px 4px 4px !important; 
  }
}
 
.SelectDiscount .mat-form-field {
  width: 100px;
  margin-bottom: 0;
  line-height: 1 !important;
}
 
.mat-card-header-text {
  margin: 0px 6px !important;
}
.searchInput:focus {
  outline: none;
}
.mat-expansion-panel-body {
  padding: 5px 15px 5px !important;
}
.dpb-0 {
  padding-bottom: 0 !important;
}
.expancemaster {
  .mat-form-field {
      margin-bottom: 0px !important;
  }
}

.productHistory .historyTableMainDiv .table_main_div .history_table {
  height: 100% !important;
  min-height: 100% !important;
}
.shopCardcContent .row div {
  margin: 2px 0;
}


.gstRadio label.mat-radio-label {
  margin-right: 20px;
}

datalist option:disabled {
  color: #aaa;
  background-color: #f5f5f5;
  pointer-events: none;
}
datalist option:disabled {
  color: #aaa;
  background-color: #f5f5f5;
  pointer-events: none;
}
.option-check{
  background-color: red !important;
}
// brand master timepicker
.mat-form-field-infix.ng-tns-c110-11 {
  padding: 15px 10px 4px 10px;
  input {
  min-height: 18px !important;
  
 }
}
