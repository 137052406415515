.defaulttheme {
    // Color
    --primary-color: #0052CC; //#fcb040
    --secondary-color: #ebebeb;
    --secondary-color-light:#3452ff12;
    --secondary-color-text: #777;
    --primary-color-text: #fff;
    --white-color: #fff;
    --sidebar-background : #f6f8fa;
    --sidebar-item-color : #333;
    --dark-color:#000;
    --btn-radius: 4px;
    --btn-font-size: 14px;
    --title-font-size: 14px; 
    --fild-radius: 3px;
    --gradient-color: linear-gradient(0deg, #0052CC 0%, #0052CC 100%);
    --gradient-color-hover: linear-gradient(0deg, #0052CC 100%, #0052CC 0%);

    // Action Iocn Color
    --edit-icon-bg:#FFE597;
    --edit-icon-color:#95803f;
    --visibility-icon-bg:#D0F5D0;
    --visibility-icon-color:#1f4d1f;
    --doc-icon-bg:rgb(177, 177, 248);
    --doc-icon-color:rgb(45 45 140);
    --delete-icon-bg:#ffcece;
    --delete-icon-color:red;
}
