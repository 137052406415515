// © 2021 .Kritin Digital solutions  All rights reserved.
// Original Author: Satish Miholiya
// Original Date: 5 may 2022
//  $primaryBglight:rgba(#3452ff, 50%)

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,800;0,900;1,300;1,400;1,500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Lobster&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

h1,
h2,
h3,
h4,
h5,
h6,
a {
  font-family: "Poppins", sans-serif !important;
}
a {
  font-weight: 500 !important;
}
.mainTable thead tr th {
  color: var(--dark-color) !important;
}
.mat-sort-header-arrow {
  color: var(--dark-color) !important;
}

.product img {
  background: #ebebeb;
  border-radius: 50%;
  padding: 4px;
}

.mainCard {
  height: calc(100vh - 110px);
  overflow: auto;
}

section.main-body mat-card.mainCards {
  padding: 4px !important;
}

.card_set .mat-card-content {
  min-height: calc(100vh - 180px) !important;
}

.filter button.btn.btn-primary.w-100 {
  height: 42px !important;
}

input.mat-input-element {
    height: 14px !important;
    min-height: 14px !important;
    line-height: 1 !important;
}

app-button {margin-top: 0 !important;
    button.btn.btn-primary , button.btn.secondary{
      height: 35px !important;
    }
}

.labelTitle{
    background-color: var(--secondary-color-light) !important;}

    button.mat-focus-indicator.mat-icon-button.mat-button-base.addBtn {
        width: 36px;
        height: 43px;
        margin-bottom: 20px;
        margin-left: 4px;
        border: 1px solid #e0e0e0;
        border-radius: var(--fild-radius);
        margin-top:-4px;
    
    
    } 
    .personButton {
        .addBtn {
            margin-left: 0px !important;
            border: inherit !important;
        }
    }
    // .mainCards button.mat-focus-indicator.mat-icon-button.mat-button-base.ng-star-inserted {
    //     margin-bottom: 30px !important;
    // }

    .tableFilter .mat-form-field {
        margin-bottom: 10px !important;
        margin-top: 10px !important;
        background: #fff !important;
    }


    section.main-body {
        max-height: calc(100vh - 80px) !important;
        height: 100%;
        min-height: calc(100vh - 80px) !important;
        overflow: auto;

    }

    .mat-drawer-content{
        height:auto !important;
        overflow: hidden;
    }


    mat-card.mainCards {
        max-height: calc(100vh - 220px) !important;
        height: 100% !important;
        min-height: calc(100vh - 220px) !important;
    }

    // .mainTable {
    //     height: calc(100vh - 225px) !important;
    //     min-height: calc(100vh - 225px) !important;
    //     overflow: auto;
    // }

    .sales .mainCard.tab_table .mainTable {
        height: calc(100vh - 225px) !important;
        min-height: calc(100vh - 225px) !important;
        overflow: auto;
    }
    .secondary{
        background-color: var(--secondary-color);
        color: var(--secondary-color-text);
    }

    .Receivable{
        .mat-tab-body {
            margin: 0 !important;
        }
    }


    .edit{
        background-color:var(--edit-icon-bg) !important;
        // margin: 0 5px !important;
        mat-icon{
            color:var(--edit-icon-color)!important;
            font-size:20px;
            height: 20px !important;
           width: 20px !important;
        }

    }
    .doc{
        background-color: var(--doc-icon-bg) !important;
        // margin: 0 5px !important;
        mat-icon{
            color:var(--doc-icon-color) !important;
           font-size:20px;
           height: 20px !important;
           width: 20px !important;

        }
        
    }
    .visibility{
        background-color: var(--visibility-icon-bg) !important;
        // margin: 0 5px !important;
        mat-icon{
            color:var(--visibility-icon-color) !important;
           font-size:20px;
           height: 20px !important;
           width: 20px !important;
        }
        
    }
    label.inputlabel.defaultColor {
      min-height: 29px !important;
      height: 37px !important;
      line-height: 15px;
  }
  .stock span.mat-button-toggle-label-content {
    margin-right: 10px;
}

// td.challanStatuaName span {
//   color: #fff;
//   background: #ff3c3c99;
//   padding: 3px;
//   border-radius: 12px;
// }

td.cashDiscount mat-form-field {
  margin-bottom: 0 !important;
}
.btns button.btn.btn-dark {
  height: 42px !important;
}

.app_time1{
  .mat-input-element[type=time]::after {
    display: none !important;
  }
}

.shop-body .page-title-wrapper {
  display: none !important;
}
.preview{
width: 140px !important;
}

// employee brand card
.employee .row.brandGrade{
  span.mat-checkbox-inner-container {
    margin: 5px;
  }

  span.mat-checkbox-label {
    width: 100%;
}
label.mat-checkbox-layout {
  display: flex !important;
  background: #fff;
  padding: 10px;
  justify-content: flex-start;
  align-items: unset;
  box-shadow: 10px 3px 10px #0000000f;
  flex-wrap: wrap;
  border-radius: 6px;
}
tbody label.mat-checkbox-layout {
  padding: 2px;
  border: none !important;
}
}

// checkbox wihth conten
// employee brand card
.employee .row.brandGrade.shopcard{
  span.mat-checkbox-inner-container {
    margin: 5px;
  }

  span.mat-checkbox-label {
    width: 100%;
}
label.mat-checkbox-layout {
  display: flex !important;
   margin-right: 10px;
    transition: 0.4s;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../images/shopBG1.png");
  }
  
}

mat-card-content.mat-card-content.card-body.shop-card-content {
  background: #f5f5f5 !important;
}